import * as Yup from 'yup';

export const SignUpSchema = Yup.object().shape({
    firstName: Yup.string()
      .required('First name is required')
      .matches(/^[A-Za-z_ ]*$/, 'Please enter valid First Name'),

    lastName: Yup.string()
      .required('Last name is required')
      .matches(/^[A-Za-z_ ]*$/, 'Please enter valid Last Name'),

    email: Yup.string()
      .email()
      .required('Email is required')
      .matches(/@[^.]*\./, 'Please enter valid Email'),

    password: Yup.string()
      .required('Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),
      checkTick: Yup.boolean()
      .oneOf([true], 'Please agree to the terms and conditions') // Ensure checkTick is true
      .required('Field is required')
  })


 export const SignInSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required')
  })

  export const recoveryPasswordSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
  })


  export const changePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required('New password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number'),
    newPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match')
      .required('Confirm new password is required')
      .min(8, 'Password must be at least 8 characters long')
      .matches(/[a-z]/, 'Password must contain at least one lowercase letter')
      .matches(/[A-Z]/, 'Password must contain at least one uppercase letter')
      .matches(/[0-9]/, 'Password must contain at least one number')
  });

  
 export const AddKitSchema = Yup.object().shape({
    kitId: Yup.string().required('Kit ID is required'),

    dob_day: Yup.number()
      .required('Day is required')
      .positive('Must be more than 0')
      .integer('Only digits allowed')
      .min(1, 'Must be more than or equal to 1')
      .max(31, 'Must be less than or equal to 31'),
    dob_year: Yup.number().required('Year is required').positive('Must be more than 0').integer('Only digits allowed'),
    dob_month: Yup.number()
      .required('Month is required')
      .positive('Must be more than 0')
      .integer('Only digits allowed')
      .min(1, 'Must be more than or equal to 1')
      .max(12, 'Must be less than or equal to 12')
  })




export const personalInfoSchema = Yup.object().shape({
    firstName: Yup.string()
      .required("Legal First Name is required")
      .matches(/^[A-Za-z]*$/, "Please enter valid First Name")
      .max(40, "First name can be of max length 40"),

    lastName: Yup.string()
      .required("Legal Last Name is required")
      .matches(/^[A-Za-z]*$/, "Please enter valid Last Name")
      .max(40, "Last name can be of max length 40"),

    weight: Yup.number()
      .required("Weight is required")
      .positive("Must be more than 0")
      .min(1, "Must be more than or equal to 1")
      .test("max", "Weight can be of max length 3", (value) => {
        if (value === undefined) return true; // Skip validation if value is not set
        return /^\d{1,3}(\.\d{0,2})?$/.test(value.toString());
      }),

    height: Yup.number()
      .required("Height is required")
      .positive("Must be more than 0")
      .min(1, "Must be more than or equal to 1")
      .test("max", "Height can be of max length 2", (value) => {
        if (value === undefined) return true; // Skip validation if value is not set
        return /^\d{1,2}(\.\d{0,1})?$/.test(value.toString());
      }),

    heightInInch: Yup.number()
      .required("Height is required")
      .positive("Must be positive")
      .min(0, "Must be more than or equal to 0")
      .test("max", "Height can be of max length 2", (value) => {
        if (value === undefined) return true; // Skip validation if value is not set
        return /^\d{1,2}(\.\d{0,1})?$/.test(value.toString());
      }),

    address1: Yup.string().required("Address is required"),

    country: Yup.string().required("country is required"),

    zipCode: Yup.string()
      .required("Zip Code is required")
      .matches(/^[0-9]*$/, "Please enter valid zip code")
      .max(12, "zip code can be of max length 12"),

    mobileNumber: Yup.string()
      .required("Mobile Number is required")
      .min(11, "Mobile Number can be of max length 11"),

    homeNumber: Yup.string()
      // .required("Home Number is required")
      .max(14, "Home Number can be of max length 14"),

    workNumber: Yup.string()
      // .required("Work Number is required")
      .max(14, "Work Number can be of max length 14"),

    gender: Yup.string()
      .required("Gender is required")
      .min(2, "Gender is required")
      .nullable(),

    dob: Yup.string().required("DOB field is required"),
  });




export const historySchema = Yup.object().shape({
    pastmmedicalhistory: Yup.string()
      .required('Past Medical History is required')
      .max(512, 'Past Medical History can be of max length 512'),

    pastsurgicalhistory: Yup.string()
      .required('Past Surgical History is required')
      .max(512, 'Past Surgical History can be of max length 512'),

    currentmedications: Yup.string().required('Current Medications is required').max(512, 'Current Medications can be of max length 512'),

    currentsupplements: Yup.string().required('Current Supplements is required').max(512, 'Current Supplements can be of max length 512'),

    allergies: Yup.string().required('Allergies is required').max(512, 'Allergies can be of max length 512'),

    colonoscopy: Yup.string().required('Colonoscopy field is required'),

    prostateExam: Yup.string().required('Prostate Exam field is required'),

    mammogram: Yup.string().required('Mammogram field is required'),

    papSmear: Yup.string().required('Pap Smear field is required'),

    // curntPrimaryCarePhys: Yup.string()
    // .required("Primary Care Physician field is required")
    // .max(60, 'Name can be of max length 60'),

    // physTelephone:Yup.string()
    // .required("Physician's Telephone is required")
    // .max(14, 'State name can be of max length 14'),

    physLastYear: Yup.string().required('Have you had a physical in the last year field is required'),

    haStHbPEtc: Yup.string().required('Cardiovascular Disease field is required'),

    DiaThEnDis: Yup.string().required('Diabetes, Thyroid or Other Endocrine Disorder field is required'),

    cancer: Yup.string().required('Cancer is field required'),

    otherIllnesses: Yup.string().required('Other Illnesses field is required'),

    tobacco: Yup.string().required('Do you currently or have you ever used tobacco products? field is required'),

    drink: Yup.string().required('Do you drink alcohol? field is required'),

    recreDrugs: Yup.string().required('Do you use recreational drugs? field is required'),

    excercise: Yup.string().required('Do you exercise regularly? field is required')
  })


  export const changeOldPasswordSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Password is required'),

    newPassword: Yup.string()
      .required('New Password is required')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character'
      ),

    confirmNewPassword: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Confirm Password must match with New Password')
  })


  export const catalogSchema=  Yup.object({
    productName: Yup.string().required("Product Name is required"),
    description: Yup.string().required("Description is required"),
    basePrice: Yup.number().required("Base Price is required").positive("Base Price must be greater than zero"),
    // productImage: Yup.mixed().required("Product Image is required").test(
    //   "fileSize",
    //   "The file is too large",
    //   (value) => !value || (value && value.size <= 2 * 1024 * 1024) // 2 MB limit
    // ).test(
    //   "fileFormat",
    //   "Unsupported Format",
    //   (value) => !value || (value && ["image/jpg", "image/jpeg", "image/png"].includes(value.type))
    // ),
    productImage: Yup.string().required("Product Image is required")
  })

// Define the intervals as static constants for validation purposes

  const intervals = {
    DAY: 365,
    WEEK: 52,
    MONTH: 12,
    YEAR: 1
  }

  // Define the validation schema for the form
export const planValidationSchema = Yup.object().shape({
  productName: Yup.string().required('Product Name is required'),
  name: Yup.string().required('Plan Name is required'),
  description: Yup.string().required('Plan Description is required'),
  currentPrice: Yup.number().required('Current Price is required'),
  price: Yup.number().required('Plan Price is required'),
  interval_unit: Yup.string().required('Interval Unit is required'),
  interval_count: Yup.number()
    .required('Interval Count is required')
    .min(0, 'Interval Count cannot be less than 0')
    .test('is-valid-interval-count', 'Interval Count is too large', function (value) {
      const maxCount =
        this.parent.interval_unit === 'DAY'
          ? intervals.DAY
          : this.parent.interval_unit === 'WEEK'
            ? intervals.WEEK
            : this.parent.interval_unit === 'MONTH'
              ? intervals.MONTH
              : intervals.YEAR

      return value <= maxCount
    }),
  total_cycles: Yup.number()
    .required('Total Cycles is required')
    .min(0, 'Total Cycles cannot be less than 0')
    .max(999, 'Total Cycles cannot be greater than 999')
})