import React from "react";
import "../IntakeData/IntakeData.scss";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { useEffect, useState, useRef } from "react";
import Zoom from "react-medium-image-zoom";
import ReactPaginate from "react-paginate";
import "react-medium-image-zoom/dist/styles.css";
import Layout from "../Layouts/layout";
import { fetchData } from "../../../services/apiService";
import { decryptedData, encryptData } from "../../../services/encryptionDecription.service";

function IntakeData() {
  const [dbData, setdbData] = useState("");
  const [loader, setLoader] = useState(false);
  const [pageLoader, setPageLoader] = useState(true);
  const [totalPage, setTotalPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(0);
  // Tabs
  const [value, setValue] = React.useState("1");

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected + 1);
    dataFetch(selectedPage.selected + 1);
  };

  function formatUSPhoneNumber(phoneNumber) {
    const cleaned = phoneNumber.replace(/\D/g, ''); // Remove non-numeric characters

    if (cleaned.length === 11) {
      const areaCode = cleaned.substring(1, 4);
      const prefix = cleaned.substring(4, 7);
      const lineNumber = cleaned.substring(7);

      return `+1 (${areaCode}) ${prefix}-${lineNumber}`;
    }

    // Return original if not a valid US phone number
    return phoneNumber;
  }

  function formatDate(inputDate) {
    const dateObject = new Date(inputDate);
    if (isNaN(dateObject.getTime())) {
      return 'Invalid Date';
    }

    const month = (dateObject.getMonth() + 1).toString().padStart(2, '0');
    const day = dateObject.getDate().toString().padStart(2, '0');
    const year = dateObject.getFullYear();

    return `${month}/${day}/${year}`;
  }


  const dataFetch = async () => {
    try {
      setPageLoader(true);
      let results = await fetchData("/api/intakeForms/personalinformation");
      setTotalPage(results.data.totalPages);
      setdbData(results.data.data);
      setPageLoader(false);
    } catch (err) {
      setPageLoader(false);
    }
  };

  useEffect(() => {
    dataFetch();
  }, []);

  return (


    <>
      <Layout>


        <div className="main_content intake_data">
          <div className="container">
            <div className="main_block">
              <div className="left_part">
                <div className="main_title mb-4">Intake Form Data</div>
              </div>

              <div className="bottom_bar">
                <div className="table_wrapper intake_form">
                  <div className="table_inner">
                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                          <TableRow>

                            <TableCell>Patient Picture</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Gender</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>State</TableCell>
                            <TableCell>Mobile Number</TableCell>
                            <TableCell>Date of Birth</TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {dbData &&
                            dbData.map((row, index) => (
                              <TableRow
                                key={uuidv4()}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell>
                                  <span className="mob_head">
                                    Patient Picture
                                  </span>
                                  <span className="text-capitalize">
                                  <Zoom>
      <picture>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="100"
          height="100"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="productImage"
        >
          <circle cx="12" cy="8" r="4" /> {/* Head */}
          <path d="M16 16c-2 0-4 1-4 1s-2-1-4-1-4 2-4 4v2h16v-2c0-2-2-4-4-4z" /> {/* Body */}
        </svg>
      </picture>
    </Zoom>
                                  </span>
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head">Name</span>
                                  <Link
                                    to={`/app/patient-details/${btoa(encryptData(row.UserId.toString()))}`}
                                  >
                                    <span className="text-capitalize">
                                      {decryptedData(row.firstName)}{" "}
                                      {decryptedData(row.lastName)}
                                    </span>
                                  </Link>
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head ">Gender</span>
                                  <span className="text-capitalize">{decryptedData(row.gender) || ""}</span>
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head">Email</span>
                                  {decryptedData(row.email) || ""}
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head">State</span>
                                  {decryptedData(row.state) || ""}
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head">
                                    Mobile Number
                                  </span>
                                  {formatUSPhoneNumber(decryptedData(row.mobileNumber))}
                                </TableCell>
                                <TableCell>
                                  <span className="mob_head">Date of Birth</span>
                                  {formatDate(decryptedData(row.dob))}
                                </TableCell>
                              </TableRow>
                            ))}
                          {dbData.length === 0 ? (
                            <TableRow
                              key={uuidv4()}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell colSpan={9}>
                                <div className=" notice">
                                  <div className="alert">
                                    <span className="alertText">
                                      No patient profiles found
                                    </span>
                                  </div>
                                </div>
                              </TableCell>
                            </TableRow>
                          ) : (
                            ""
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                </div>
              </div>
            </div>
            {/* Pagination */}
            {dbData.length != 0 ? (
              <ReactPaginate
                breakLabel="..."
                nextLabel="next >"
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={Math.ceil(totalPage / 10)}
                previousLabel="< previous"
                renderOnZeroPageCount={null}
                marginPagesDisplayed={2}
                containerClassName="pagination justify-content-center"
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                activeClassName="active"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </Layout>

    </>
  );
}

export default IntakeData;
