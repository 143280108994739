import React from 'react'
import PreLoginHeader from '../PreLoginHeader/PreLoginHeader'
import Footer from '../Footer/Footer'
import { Link } from 'react-router-dom'
import '../TermsAndConditions/TermsAndConditions.scss'

function TermsAndConditions() {
  return (
    <>
      <PreLoginHeader></PreLoginHeader>
      <div className='main_content terms_condition'>
        <div className='breadcum_wrapper' aria-label='breadcrumb'>
          <div className='container'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='/home'>Home</Link>
              </li>
              <li className='breadcrumb-item'>
                <Link to='/aboutus'>Terms</Link>
              </li>
            </ol>
            <div className='breadcrumb_title'>Terms and Conditions</div>
          </div>
        </div>

        <div className='content_wrapper cms_page'>
          <div className='container'>
            <div className='title_wrapper'>
              <h2 className='main_title'>our</h2>
              <h4 className='sub_title'>Terms and Conditions</h4>
            </div>

            <div className='content'>
              <p>
                Please read these Terms and Conditions carefully before using our services provided by HRT.ORG. By accessing or using our
                services, you agree to be bound by these terms. If you don not agree with any part of these terms, please do not proceed to
                use our services.
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>Service Provision:</h6>
              <p>
                a. HRT.ORG provides medical testing, analysis and related healthcare services.
                <br />
                b. Our services are intended for information and diagnostic purposes and should not substitute professional medical service
                or treatment.
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>Test Results:</h6>
              <p>
                a. Test results provided by HRT.ORG are based on scientific analysis and professional expertise. However, they may not
                guarantee a definitive diagnosis.
                <br />
                b. It is essential to consult with a qualified healthcare professional for interpretation and further advice based on your
                test results
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>User Responsibilities:</h6>
              <p>
                a. You are responsible for providing accurate and complete information when using our services.
                <br />
                b. You agree to comply with all applicable laws and regulations regarding the use of our services. c. You are responsible
                for maintaining the confidentiality of any login credentials or account information provided to you.
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>Intellectual Property:</h6>
              <p>
                a. All content, trademarks, logos, and intellectual property on the HRT.ORG website and related materials are the property
                of HRT.ORG or its license.
                <br />
                b. You may not reproduce, modify, distribute, or use any copyrighted materials without prior written permission from HRT.ORG
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>Limitation of Liability:</h6>
              <p>
                a. HRT.ORG is not liable for any direct, indirect, incidental, or consequential damages arising from the use or inability to
                use our services.
                <br />
                b. We do not guarantee the accuracy, completeness, or reliability of the information provided through our services.
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>Modifications to Terms and Services:</h6>
              <p>
                {' '}
                a. HRT.ORG reserves the right to modify these Terms and Conditions at any time. The updated terms will be posted on our
                website.
                <br />
                b. We may also modify or discontinue any aspect of our services at any time without prior notice.
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>Privacy:</h6>
              <p>
                Our collection, use and disclosure of your personal information are governed by our Privacy Policy. Please refer to our{' '}
                <Link to='/privacy-policy'>Privacy Policy</Link> for more information.
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>Governing Law:</h6>
              <p>
                These Terms and Conditions shall be governed by and construed in accordance with the laws of the jurisdiction where HRT.ORG
                is located.
              </p>
            </div>

            <div className='content'>
              <p>If you have any questions or concerns about our Terms and Conditions please feel free to contact us.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}

export default TermsAndConditions
