import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Layout from "../../Layouts/layout";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { fetchData, updateMultipartData,updateData } from "../../../../services/apiService";
import { catalogSchema } from "../../../../validations/validation";
import useAlertUtils from "../../../../utils/common.utils";

function CatalogEdit() {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("productId");
  const [variants, setVariants] = useState([]);
  const [newVariant, setNewVariant] = useState({ strength: "", image: null, basePrice: "" });
  const { alertSuccess, alertError } = useAlertUtils();

  useEffect(() => {
    // Fetch the catalog data by ID to populate the form
    async function fetchCatalog() {
      console.log("id", id);
      const response = await fetchData(`/api/catalog/${id}`); // Fetch the catalog by ID
      if (response.success) {
        console.log("🚀 ~ fetchCatalog ~ response:", response);
        const data = response.data;
        formik.setValues({
          productName: data.productName,
          description: data.description,
          basePrice: data.basePrice,
          productImage:data.productImage, 
        });
        setVariants(data.variants || []); 
      } else {
        alertError(response.message);
      }
    }
    fetchCatalog();
  }, [id]);

  
  // Formik form setup
  const formik = useFormik({
    initialValues: {
      productName: "",
      description: "",
      basePrice: "",
      productImage: null,
    },
    validationSchema: catalogSchema,
    onSubmit: async (values, { resetForm }) => {
      console.log("🚀 ~ onSubmit: ~ values:", values)
      
      const formData = new FormData();
      console.log("🚀 ~ onSubmit: ~ formData:", formData)
      formData.append("productName", values.productName);
      formData.append("description", values.description);
      formData.append("basePrice", values.basePrice);
      if (values.productImage) {
        formData.append("productImage", values.productImage);
      }

      variants.forEach((variant, index) => {
        formData.append("variant_strength[]", variant.strength);
        console.log("🚀 ~ variants.forEach ~ variant:", variant.id)

        formData.append("variant_basePrice[]", variant.basePrice);
        formData.append("id",variant.id ? variant.id : null)
        if(typeof(variant.image)== "string")
        {
          console.log('entered>>',variant.image)
          formData.append("variant_previous_image",variant.image)
        }
        else{
          formData.append("variant_image",variant.image)
        }
        console.log("formData 1",formData)
        // if (variant.image instanceof File) {
        //   formData.append("variant_image[]", variant.image);
        // } else {
        //   formData.append("variant_image[]", variant.image); // For existing image URLs
        // }
      });
    
      console.log("message before updating records")
      //Making a PUT request to update the catalog
   
      const response = await updateData(`/api/catalog//update/${id}`, formData);

      if (response.success) {
        resetForm();
        formik.setFieldValue("productImage", null);
        formik.setFieldValue("variantImage", null);
        setVariants([]);
        alertSuccess(response.message);
        navigate("/app/catalogs"); // Redirect after successful update
      } else {
        alertError(response.message);
      }
    },
  });

  const handleAddRow = () => {
    if (newVariant.strength && newVariant.basePrice && newVariant.image) {
      setVariants([...variants, newVariant]);
      setNewVariant({ strength: "", image: null, basePrice: "" });
    } else {
      alert("Please fill all fields for the variant.");
    }
  };

  const handleRemoveVariant = (index) => {
    console.log("🚀 ~ handleRemoveVariant ~ updatedVariants:",variants)
    const updatedVariants = variants.filter((_, i) => i !== index);
    console.log("🚀 ~ handleRemoveVariant ~ updatedVariants:", updatedVariants)
   setVariants(updatedVariants);
  };


  console.log("newVariant",newVariant)
  return (
    <>
      <Layout>
        <div className="main_content">
          <div className="container">
            <div className="main_block">
              <div className="top_bar">
                <div className="left_part">
                  <div className="main_title">Edit Catalog</div>
                </div>
              </div>

              <form onSubmit={formik.handleSubmit} encType="multipart/form-data">
                <div className="form_field_wrapper">
                  <label>
                    Product Name<span className="req">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Product Name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.productName}
                    className="form-control inp_text"
                    id="productName"
                  />
                  <p className="error">
                    {formik.errors.productName && formik.touched.productName && formik.errors.productName}
                  </p>
                </div>

                <div className="form_field_wrapper">
                  <label>
                    Description<span className="req">*</span>
                  </label>
                  <textarea
                    placeholder="Product Description"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    className="form-control inp_text"
                    id="description"
                  />
                  <p className="error">
                    {formik.errors.description && formik.touched.description && formik.errors.description}
                  </p>
                </div>

                <div className="form_field_wrapper">
                  <label>
                    Base Price<span className="req">*</span>
                  </label>
                  <input
                    type="number"
                    placeholder="Base Price"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.basePrice}
                    className="form-control inp_text"
                    id="basePrice"
                  />
                  <p className="error">
                    {formik.errors.basePrice && formik.touched.basePrice && formik.errors.basePrice}
                  </p>
                </div>

                <div className="form_field_wrapper">
  <label>
    Product Image<span className="req">*</span>
  </label>
  <input
    type="file"
    accept="image/jpg, image/jpeg, image/png"
    onChange={(event) => {
      console.log('fil',event.target.files[0])
      formik.setFieldValue("productImage", event.target.files[0]);
    }}
    onBlur={formik.handleBlur}
    
    className="form-control"
    id="productImage"
  />
  <p className="error">
    {formik.errors.productImage && formik.touched.productImage && formik.errors.productImage}
  </p>
</div>


                <hr />

                {/* Add Variant Section */}
                <div className="add_variant_section">
                  <h3>Add Variant</h3>
                  <div className="form_field_wrapper">
                    <label>
                      Strength<span className="req">*</span>
                    </label>
                    <input
                      type="text"
                      placeholder="Variant Strength"
                      value={newVariant.strength}
                      onChange={(e) => setNewVariant({ ...newVariant, strength: e.target.value })}
                      className="form-control inp_text"
                    />
                  </div>
                  <div className="form_field_wrapper">
                    <label>
                      Variant Image<span className="req">*</span>
                    </label>
                    <input
                      type="file"
                      accept="image/jpg, image/jpeg, image/png"
                      onChange={(e) => setNewVariant({ ...newVariant, image: e.target.files[0] })}
                      className="form-control"
                      name="variantImage"
                    />
                  </div>
                  <div className="form_field_wrapper">
                    <label>
                      Base Price<span className="req">*</span>
                    </label>
                    <input
                      type="number"
                      placeholder="Variant Base Price"
                      value={newVariant.basePrice}
                      onChange={(e) => setNewVariant({ ...newVariant, basePrice: e.target.value })}
                      className="form-control inp_text"
                    />
                  </div>
                  <button
                    type="button"
                    onClick={handleAddRow}
                    className="btn btn-primary"
                  >
                    Add Variant
                  </button>
                </div>

                <hr />
                {/* Variants Table */}
                {variants.length > 0 && (
                  <TableContainer component={Paper} className="variant_table_container">
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Strength</TableCell>
                          <TableCell>Product Image</TableCell>
                          <TableCell>Base Price</TableCell>
                          <TableCell>Remove</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {variants.length > 0 && variants.map((variant, index) => (
                          
                          <TableRow key={index}>
                            <TableCell>{variant.strength}</TableCell>
                            <TableCell>
                             <img
    src={`http://127.0.0.1:4000/public/uploads/${variant.image}`} // Adjust path if needed
    alt="variant"
    style={{ width: "100px", height: "100px" }} // Adjust size as needed
  />
</TableCell>


                            <TableCell>{variant.basePrice}</TableCell>
                            <TableCell>
                              <IconButton onClick={() => handleRemoveVariant(index)}>
                                <CloseIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}

                <div className="form_btn_wrapper mt-5">
                  <button
                    className="btn btn-secondary"
                    onClick={() => navigate("/catalogs")}
                    style={{ marginRight: "1rem" }}
                  >
                    Cancel
                  </button>
                  <button className="btn btn-primary" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default CatalogEdit;
