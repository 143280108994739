import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Grid,
  Paper,
  Divider,
  Avatar,
  Box,
  IconButton,
} from '@mui/material';
import { Person, LocationOn, Payment, ShoppingCart, ArrowBack } from '@mui/icons-material';
import Layout from '../Layouts/layout';
import { useNavigate, useParams } from 'react-router-dom';
import { fetchData } from '../../../services/apiService';
import './PatientOrder.scss'
function OrderView() {
  const navigate = useNavigate();

  const {id} = useParams()

  const [data,setData]=useState({})

  const fetchOrder =async()=>{
    try {
        let results = await fetchData(
            `/api/catalog/patientOrder/view?id=${id}`
          );
          setData(results.data.data)
    } catch (error) {
        
    }
    

    
   
  }

  useEffect(()=>{
    fetchOrder()
  },[])
//   const data = {
//     id: 1,
//     address1: 'sdaffds',
//     city: 'tenesse',
//     country: 'United States',
//     countryCode: 'US',
//     firstName: 'Jack',
//     lastName: 'lama',
//     pinCode: '11111',
//     state: 'Alabama',
//     stateCode: 'AL',
//     facilitatorAccessToken:
//       'A21AALEJwhUvP_jLYpMLriRUJvb3LJwgxhApKHw_gnqkgSBZEBHwQeTdmbKhLsFG2T8h1AlD7JNiOq3TdFGt106Qh-YEtU0eg',
//     grandTotal: '4000.00',
//     intent: 'AUTHORIZE',
//     orderCreatedAt: '2024-08-27T05:48:13.000Z',
//     orderId: '02U455220G0516541',
//     payerAddressCountryCode: null,
//     payerEmailAddress: null,
//     payerGivenName: null,
//     payerSurname: null,
//     payerId: null,
//     paymentSource: 'paypal',
//     paypalPlanId: null,
//     planId: null,
//     productId: 5,
//     productImage:
//       'http://127.0.0.1:4000/public/uploads/productImage-1724409998484-355271105.png',
//     productTitle: 'PCM',
//     quantity: 10,
//     status: 2,
//     strength: null,
//     subTotal: '400.00',
//     subscriptionID: null,
//     total: '4000.00',
//     type: null,
//     userId: 3,
//   };

  return (
    <Layout>
      <Grid container justifyContent="center" style={{ padding: '20px' }}>
        <Grid item xs={12} md={10}>
          <Paper
            elevation={3}
            style={{
              padding: '30px',
              margin: '20px auto',
              backgroundColor: '#f8f9fa',
              borderRadius: '10px',
            }}
          >
            <Box display="flex" alignItems="center" marginBottom={3}>
              <IconButton
                color="primary"
                onClick={() => navigate('/app/patient-order')}
              >
                <ArrowBack />
              </IconButton>
              <Typography
                variant="h4"
                gutterBottom
                style={{ flexGrow: 1, textAlign: 'center', fontWeight: 'bold' }}
              >
                Order Details
              </Typography>
            </Box>

            <Grid container spacing={4}>
              {/* Left Column - Product, Customer, and Payment Details */}
              <Grid item xs={12} md={7}>
                <Card
                  variant="outlined"
                  style={{
                    backgroundColor: '#ffffff',
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    marginBottom: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <CardContent style={{ flex: 1 }}>
                    {/* Product Information */}
                    <Box display="flex" alignItems="center" marginBottom={2}>
                      <ShoppingCart color="primary" style={{ marginRight: '10px' }} />
                      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        Product Information
                      </Typography>
                    </Box>
                    <Box
                      display="flex"
                      alignItems="flex-start"
                      marginBottom={2}
                      style={{ padding: '10px' }}
                    >
                      <Avatar
                        variant="square"
                        src={data.productImage}
                        alt={data.productTitle}
                        style={{
                          width: '150px',
                          height: '150px',
                          borderRadius: '8px',
                          marginRight: '20px',
                        }}
                      />
                      <Box>
                        <Typography variant="body1" gutterBottom>
                          <strong>Product Title:</strong> {data.productTitle}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Quantity:</strong> {data.quantity}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Subtotal:</strong> ${data.subTotal}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <strong>Total:</strong> ${data.total}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider style={{ margin: '20px 0' }} />

                    {/* Customer Info */}
                    <Box display="flex" alignItems="center" marginBottom={2}>
                      <Person color="primary" style={{ marginRight: '10px' }} />
                      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        Customer Information
                      </Typography>
                    </Box>
                    <Typography variant="body1" gutterBottom>
                      Name: {data.firstName} {data.lastName}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <LocationOn color="action" style={{ marginRight: '5px' }} />
                      Address: {data.address1}, {data.city}, {data.state} {data.pinCode}, {data.country}
                    </Typography>
                    <Divider style={{ margin: '20px 0' }} />
                  </CardContent>
                </Card>
              </Grid>

              {/* Right Column - Order and Payment Details */}
              <Grid item xs={12} md={5}>
                <Card
                  variant="outlined"
                  style={{
                    backgroundColor: '#ffffff',
                    borderRadius: '10px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    marginBottom: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%',
                  }}
                >
                  <CardContent style={{ flex: 1 }}>
                    {/* Order Details */}
                    <Box display="flex" alignItems="center" marginBottom={2}>
                      <Typography variant="h6" color="primary" style={{ fontWeight: 'bold' }}>
                        Order ID: {data.orderId}
                      </Typography>
                    </Box>
                    <Typography variant="body1" color="textSecondary" gutterBottom>
                      Order Date: {new Date(data.orderCreatedAt).toLocaleDateString()}
                    </Typography>
                    <Typography variant="body1" gutterBottom style={{
        color: data.status === 1
          ? 'orange' // PENDING
          : data.status === 2
          ? 'green'  // APPROVED
          : data.status === 3
          ? 'blue'   // COMPLETED
          : data.status === 4
          ? 'red'    // CANCELLED
          : 'gray',  // UNKNOWN
        fontWeight: data.status === 1 || data.status === 2 || data.status === 3 || data.status === 4
          ? 'bold'
          : 'normal',
      }}>
                      Status: {data?.status === 1
                                    ? "PENDING"
                                    : data?.status === 2
                                    ? "APPROVED"
                                    : data?.status === 3
                                    ? "COMPLETED"
                                    : data?.status === 4
                                    ? "CANCELLED"
                                    : "UNKNOWN"}
                    </Typography>
                    <Divider style={{ margin: '20px 0' }} />

                    {/* Payment Info */}
                    <Box display="flex" alignItems="center" marginBottom={2}>
                      <Payment color="primary" style={{ marginRight: '10px' }} />
                      <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                        Payment Information
                      </Typography>
                    </Box>
                    <Typography variant="body1" gutterBottom>
                      Payment Source: {data.paymentSource}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Intent: {data.intent}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      Grand Total: ${data.grandTotal}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Layout>
  );
}

export default OrderView;
