import React, { useEffect, useState } from 'react'
import logo from '../../assets/img/logo.svg'
import '../Footer/Footer.scss'
import { Link } from 'react-router-dom'

const Footer = () => {
  const [type, setType] = useState(false)
  useEffect(() => {
    const uR = localStorage.getItem('uR')
    if (uR && uR == 'provider') {
      setType(true)
    }
  }, [])

  return (
    <>
      <footer>
        <div className='footer_top'>
          <div className='container'>
            <div className='row'>
              <div className='col-md-4 footer_item footer_item1'>
                <div className='footer_item_inner'>
                  <div className='footer_logo'>
                    {/* <img src={logo} /> */}
                  </div>
                  <div className='footer_desc'>Leading The Way In Medical Excellence, Trusted Care.</div>
                </div>
              </div>
              {localStorage.getItem('uid') ? (
                ' '
              ) : (
                <>
                  <div className='col-md-4 footer_item footer_item2'>
                    <h6 className='footer_title'>Important Links</h6>
                    {/* <div className="footer_link">
                             <Link  to="/home">Home</Link>
                         </div>
                         <div className="footer_link">
                             <Link  to="/about-us">About Us</Link>
                         </div> */}
                    <div className='footer_link'>
                      <Link to='/privacy-policy'>Privacy Policy</Link>
                    </div>
                    <div className='footer_link'>
                      <Link to='/terms-and-conditions'>Terms And Conditions</Link>
                    </div>
                  </div>
                  <div className='col-md-4 footer_item footer_item3'>
                    <h6 className='footer_title'>Contact Us</h6>
                    <div className='footer_link'>
                      <label>Call:</label>
                      <a href='tel:(888) 574-5524'>(888) 574-5524</a>
                    </div>
                    <div className='footer_link'>
                      <label>Email:</label>
                      <a href='mailto:support@hrt.org'>support@hrt.org</a>
                    </div>
                    {/* <div className='footer_link'>
                           <label>Address:</label>
                           <address>0123 Some place</address>
                         </div>
                         <div className='footer_link'>
                           <label>Location:</label>
                           <a href="#">Some country</a>
                         </div> */}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className='footer_bottom'>
          <div className='container'>
            <small className='copyright mt-4'>Copyright &copy; 2023 HRT. All Right Reserved.</small>
            {type && type ? (
              <Link to='/list-catalog' className=''>
                Catalogs
              </Link>
            ) : null}
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
