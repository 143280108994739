import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import { getCookie } from '../../utils/common.utils';

export const Auth = () => {
   const token= localStorage.getItem('token');
    const navigate = useNavigate();
    if(!token){
       navigate("/");
    }
  return (
     <Outlet/>
  )
}
