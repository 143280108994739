import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    value: '1'
}

const tabSlice = createSlice({
    name: "checkTab",
    initialState,
    reducers: {
        updateTab(state, action) {
            state.value = action.payload;
        }
    }
});


export const { updateTab } = tabSlice.actions;
export default tabSlice.reducer;