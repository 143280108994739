import { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import Routers from "./routes/Routers";
import { ToastProvider } from "react-toast-notifications";
import { useSelector } from "react-redux";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

function App() {
  const paypalClientKey = process.env.REACT_APP_PAYPAL_CLIENT_KEY;
  const cartProduct = useSelector((state) => state.cart);

  const [paypalOptions, setPaypalOptions] = useState({
    "client-id": 'AYjllrZcj9-COUIxZtgopFJWKhkkgfdmrZJ3qjhZ1hMIJYFOAoUR92_ReNPTsGtlh5UT6dwRIQnzFNX3',
    currency: "USD",
    vault: true,
    locale: "en_US",
    "data-sdk-integration-source": "integrationbuilder_sc",
    intent: "authorize", // Default value
  });

  useEffect(() => {
    const newIntent = cartProduct.type === "subscription" ? "subscription" : "authorize";
    setPaypalOptions((prevOptions) => ({
      ...prevOptions,
      intent: newIntent,
    }));
  }, [cartProduct.type]);

  // Unmount PayPalScriptProvider if intent changes
  const [key, setKey] = useState(0);

  useEffect(() => {
    setKey(prevKey => prevKey + 1);
  }, [paypalOptions.intent]);

  console.log('paypalOptions',paypalOptions , cartProduct.type)
  return (
    <PayPalScriptProvider key={key} options={paypalOptions}>
      <ToastProvider>
        <BrowserRouter>
          <Routers />
        </BrowserRouter>
      </ToastProvider>
    </PayPalScriptProvider>
  );
}

export default App;
