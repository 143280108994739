import React from 'react';
import Footer from '../../../components/Footer/Footer';
import Header from '../../../components/Header/HeaderProvider';

const Layout = ({ children }) => {
  return (
    <div className="layout">

      <Header/>
      <main className="content">
        {children} 
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
