export const maleSymptoms={
    "Estrogen_Dominance__Progesterone_Deficiency": [
      "Anxious",
      "Bleeding Changes",
      "Body Temperature Cold",
      "Breast Cancer",
      "Breasts - Fibrocystic",
      "Breasts - Tender",
      "Depressed",
      "Headaches",
      "Infertility",
      "Irritable",
      "Mood Swings",
      "Nervous",
      "Uterine Fibroids",
      "Water Retention",
      "Weight Gain - Hips"
    ],
    "Estrogen__Progesterone_Deficiency": [
      "Aches and Pains",
      "Acne",
      "Bone Loss",
      "Depressed",
      "Foggy Thinking",
      "Hair - Increased Facial or Body",
      "Heart Palpitations",
      "Hot Flashes",
      "Incontinence",
      "Infertility",
      "Memory Lapse",
      "Night Sweats",
      "Rapid Aging",
      "Rapid Heartbeat",
      "Skin Thinning",
      "Sleep Disturbed",
      "Tearful",
      "Urinary Urge Increased",
      "Vaginal Dryness",
      "Weight Gain - Waist"
    ],
    "High_Androgens___DHEA__Testosterone___": [
      "Acne",
      "Breast Cancer",
      "Hair - Increased Facial or Body",
      "Hair - Scalp Loss",
      "Irritable",
      "Triglycerides Elevated",
      "Weight Gain - Waist"
    ],
    "High_Cortisol": [
      "Anxious",
      "Blood Pressure High",
      "Bone Loss",
      "Breast Cancer",
      "Depressed",
      "Foggy Thinking",
      "Hot Flashes",
      "Infertility",
      "Irritable",
      "Memory Lapse",
      "Muscle Size Decreased",
      "Nervous",
      "Night Sweats",
      "Rapid Aging",
      "Rapid Heartbeat",
      "Skin Thinning",
      "Sleep Disturbed",
      "Stress",
      "Uterine Fibroids",
      "Weight Gain - Waist"
    ],
    "Hypometabolism": [
      "Aches and Pains",
      "Anxious",
      "Bleeding Changes",
      "Body Temperature Cold",
      "Breast Cancer",
      "Cholesterol High",
      "Constipation",
      "Depressed",
      "Fatigue - Evening",
      "Fatigue - Morning",
      "Fibromyalgia",
      "Foggy Thinking",
      "Goiter",
      "Hair - Dry or Brittle",
      "Hair - Scalp Loss",
      "Headaches",
      "Hearing Loss",
      "Heart Palpitations",
      "Hoarseness",
      "Hot Flashes",
      "Infertility",
      "Libido Decreased",
      "Nails Breaking or Brittle",
      "Night Sweats",
      "Pulse Rate Slow",
      "Rapid Heartbeat",
      "Sleep Disturbed",
      "Stamina Decreased",
      "Sweating Decreased",
      "Swelling or Puffy Eyes/Face",
      "Triglycerides Elevated",
      "Uterine Fibroids"
    ],
    "Low_Androgens___DHEA__Testosterone___": [
      "Aches and Pains",
      "Allergies",
      "Bone Loss",
      "Depressed",
      "Fatigue - Evening",
      "Fatigue - Morning",
      "Fibromyalgia",
      "Headaches",
      "Incontinence",
      "Libido Decreased",
      "Memory Lapse",
      "Muscle Size Decreased",
      "Rapid Aging",
      "Skin Thinning",
      "Stamina Decreased",
      "Vaginal Dryness"
    ],
    "Low_Cortisol": [
      "Aches and Pains",
      "Allergies",
      "Blood Pressure Low",
      "Blood Sugar Low",
      "Body Temperature Cold",
      "Chemical Sensitivity",
      "Fatigue - Evening",
      "Fatigue - Morning",
      "Fibromyalgia",
      "Pulse Rate Slow",
      "Stamina Decreased",
      "Stress",
      "Sugar Cravings"
    ],
    "Metabolic_Syndrome": [
      "Blood Pressure High",
      "Cholesterol High",
      "Hair - Increased Facial or Body",
      "Numbness - Feet or Hands",
      "Triglycerides Elevated",
      "Weight Gain - Waist"
    ]
  }
  