import React from 'react'
import PreLoginHeader from '../PreLoginHeader/PreLoginHeader'
import Footer from '../Footer/Footer'
import { Link } from 'react-router-dom'
import '../PrivacyPolicy/PrivacyPolicy.scss'

function PrivacyPolicy() {
  return (
    <>
      <PreLoginHeader></PreLoginHeader>
      <div className='main_content privacy_policy'>
        <div className='breadcum_wrapper' aria-label='breadcrumb'>
          <div className='container'>
            <ol className='breadcrumb'>
              <li className='breadcrumb-item'>
                <Link to='/home'>Home</Link>
              </li>
              <li className='breadcrumb-item'>
                <Link to='/aboutus'>Privacy</Link>
              </li>
            </ol>
            <div className='breadcrumb_title'>Privacy Policy</div>
          </div>
        </div>

        <div className='content_wrapper cms_page'>
          <div className='container'>
            <div className='title_wrapper'>
              <h2 className='main_title'>our</h2>
              <h4 className='sub_title'>Privacy Policy</h4>
            </div>

            <div className='content'>
              <p>
                At an HRT.ORG, we are committed to protecting the privacy and confidentiality of your personal information. This Privacy
                Policy outlines the types of information we collect, how we use and safeguard it and your rights regarding your personal
                data. Please read this policy carefully to understand our practices regarding your personal information.
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>Information we collect:</h6>
              <p>
                a. Personal Information- We may collect personal information such as your name, contact details, date of birth, gender,
                medical history and other relevant information required regarding for providing healthcare services. <br />
                b. Healthcare Information- We may collect health-related information, including medical test results, diagnoses,
                prescriptions and treatment plans, to ensure accurate analysis and diagnosis.
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>Your Rights:</h6>
              <p>
                a. Access, Correction, and Withdrawal: You have the right to access, review, and correct your personal information. You can
                also withdraw consent for the collection, use, or disclosure of your information <br />
                b. Data Portability and Complaints: You can request a structured copy of your personal information. If you have concerns,
                you can lodge a complaint with the regulatory authority.
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>Collection and Use of Information:</h6>
              <p>
                a. Consent: We collect and use your information with your consent or as required by law.
                <br />
                b. Purpose: We use your information for healthcare services and maintain strict data security measures. c. Third-Party
                Access: We do not sell or transfer your information, except to authorized healthcare professionals or as legally mandated.
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>Data Retention:</h6>
              <p>
                a. We will retain your personal information for as long as necessary to fulfill the purposes outlined in this policy, or as
                required by applicable laws and regulations.
                <br />
                b. When we no longer need your personal information, we will securely dispose of it in a manner that protects your privacy.
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>Updates to the Privacy Policy:</h6>
              <p>
                We may update this Privacy Policy from time to time to reflect changes in our practices or applicable laws. We will notify
                you of any material changes by posting the updated policy on our website.
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>Links to Third-Party Websites:</h6>
              <p>
                Our website may contain links to third-party websites. We are not responsible for the privacy practices or content of such
                websites. We recommend reviewing the privacy policies of those websites before providing any personal information.
              </p>
            </div>

            <div className='content'>
              <h6 className='content_title'>Cookies and Tracking Technologies:</h6>
              <p>
                We may use cookies and similar tracking technologies on our website to enhance your browsing experience. These technologies
                do not collect personally identifiable information unless you voluntarily provide it.
              </p>
            </div>

            <div className='content'>
              <p>
                If you have any questions or concerns about our Privacy Policy or the handling of your personal information, please feel
                free to contact us.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  )
}

export default PrivacyPolicy
