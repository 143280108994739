// src/alertUtils.js
import { ToastProvider, useToasts } from 'react-toast-notifications';

import Cookies from 'js-cookie';

export default function useAlertUtils() {

  const { addToast } = useToasts();

  const alertSuccess = (message) => {
    addToast(message,
      {
        appearance: 'success',
        autoDismiss: true,
        autoDismissTimeout: 4000
      });
  };

  const alertError = (message) => {
    addToast(message, {
      appearance: 'error', autoDismiss: true,
      autoDismissTimeout: 4000
    });
  };

  const alertWarn = (message) => {
    addToast(message, { appearance: 'error' });
  };

  return { alertSuccess, alertError, alertWarn };
}

export const setCookie = (key,value,expires) => {
  Cookies.set(key, value, { expires }); 
};

export const getCookie = (key) => {
  return Cookies.get(key);
};