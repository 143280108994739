import axios from 'axios';
import { getCookie } from '../utils/common.utils';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});
 
axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);
 
axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      window.location.href = '/';
    }
    return Promise.reject(error.response);
  }
);
 
export default axiosInstance;