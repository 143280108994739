import { configureStore } from '@reduxjs/toolkit'
import tabReducer from './slices/tab.slices'
import cartReducer from './slices/cart.scices'
const store = configureStore({
   reducer: {
      tab: tabReducer,
      cart: cartReducer
   }
});


export default store;