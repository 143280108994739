import React, { useEffect, useRef, useState } from "react";
import "./ProductDetails.scss";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useNavigate } from "react-router-dom";

import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useDispatch, useSelector } from "react-redux";
import { addToCart , paymentType } from "../../redux/slices/cart.scices";

import { useFormik } from "formik";
import * as Yup from "yup";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { usePayPalScriptReducer } from "@paypal/react-paypal-js";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { fetchData } from "../../services/apiService";



const Product_Details = () => {
  const navigate = useNavigate();
  const dispatcher = useDispatch();
  const [pageLoader, setPageLoader] = useState(true);



  const [productData, setProductData] = useState(null);
  const [selectedValue, setSelectedValue] = useState("");
  const [variantData, setVariantData] = useState(null);
  const [productImage, SetProductImage] = useState(null);
  const [allPlans, setAllPlans] = useState([]);
  const [variantPlans, setVariantPlans] = useState([]);

  const radioRef = useRef();
  // Initialize total price state
  const [totalPrice, setTotalPrice] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [planId, setPlanId] = useState("");
  const [choosenPlan, setChoosenPlan] = useState({});
  const [{ options }, dispatch] = usePayPalScriptReducer();
  const cartProduct = useSelector((state) => state.cart);
  const productId = localStorage.getItem("productId");
  const imageSource = process.env.REACT_APP_IMAGE_PATH;

  // Define validation schema using Yup
  const quantityValidationSchema = Yup.object().shape({
    quantities: Yup.number()
      .typeError('Quantity must be a number')
      .required("Quantity is required")
      .min(1, "Quantity must be at least 1")
      .integer("Quantity must be a whole number"),
  });


  // Function to handle quantity change
  const handleQuantityChange = (event) => {
    const newQuantity = parseInt(event.target.value, 10);

    if (!isNaN(newQuantity) && newQuantity >= 1) {
      // Calculate the new total price based on the selected quantity
      const isObjectEmpty = Object.keys(choosenPlan).length === 0;
      if (!isObjectEmpty) {
        // console.log(choosenPlan,"choosenPlan")
        const newTotalPrice = newQuantity * choosenPlan.basePrice;
        setTotalPrice(newTotalPrice.toFixed(2));
      } else {
        const newTotalPrice = newQuantity * variantData.basePrice;
        setTotalPrice(newTotalPrice.toFixed(2));
      }
      setQuantity(newQuantity);
      // You can also set the updated quantity in Formik values
    }
    formik.setValues({ quantities: newQuantity });

  };

  const filterVariantPlans = async (variantId, plans) => {
    const variantPlans = plans.filter((plan) => {
      return plan.variantId == variantId;
    });
    setVariantPlans(variantPlans);
    setChoosenPlan({});
    if (variantPlans[0]?.currentPrice) {
      const newTotalPrice = quantity * variantPlans[0].currentPrice;
      setTotalPrice(newTotalPrice);
    }
  };

  const handleSelectChange = (event) => {
    console.log('eeee', event.target.value)
    radioRef.current.checked = true;
    const variantId = event.target.value;
    const variant = productData.variants.find((item) => item.id == variantId);

    setVariantData(variant);
    if (variant?.image) {
      SetProductImage(imageSource + "/" + variant.image);
    }

    setSelectedValue(variant.id);
    const newTotalPrice = quantity * variant.basePrice;
    setTotalPrice(newTotalPrice);
    filterVariantPlans(variantId, allPlans);
  };

  function formatPlanTotal(planTotal) {
    if (typeof planTotal === "string") {
      // If planTotal is a string, parse it to a number and format
      return parseFloat(planTotal).toFixed(2);
    } else if (typeof planTotal === "number") {
      // If planTotal is already a number, format it directly
      return planTotal.toFixed(2);
    }
    // Handle other cases or return a default value if needed
    return "0.00";
  }

  const handleBuyNow = async (values) => {

    try {
      setPageLoader(true);
      let planTotal = "";
      if (planId) {
        planTotal = quantity * choosenPlan.price;
      } else {
        planTotal = totalPrice;
      }
      // const totalPrice = variantData.price * values.quantities;

     console.log("product",productData)
      const productDetails = {
        productId: localStorage.getItem("productId"),
        paypalProductId:productData?.paypalProductId,
        
        productTitle: productData.productName,
        selectedValue,
        productPrice: variantData.basePrice,
        productImageUrl: productImage,
        quantity: quantity,
        totalPrice: formatPlanTotal(planTotal),
        variantData,
        planId: planId,
        plan: choosenPlan,
      };
      console.log("🚀 ~ handleBuyNow ~ productDetails:", productDetails)
      dispatcher(addToCart(productDetails));

      // const clientToken = await clientDataToken();

      // if (!planId) {
      //   dispatch({
      //     type: "resetOptions",
      //     value: {
      //       ...options,
      //       intent: "authorize",
      //       vault: false,
      //       "data-client-token": clientToken.data,
      //     },
      //   });
      // } else {
      //   dispatch({
      //     type: "resetOptions",
      //     value: {
      //       ...options,
      //       intent: "subscription",
      //       vault: true,
      //       "data-client-token": clientToken.data,
      //     },
      //   });
      // }

      navigate("/app/checkout");
    } catch (err) {
      alert("Something went wrong.Please try again");
    } finally {
      setPageLoader(false);
    }
  };

  const handlePaymentTypeChange = (type, id, plan) => {
    console.log("🚀 ~ handlePaymentTypeChange ~ plan:", plan)
    dispatcher(paymentType(type));
    setPlanId(id);
    setChoosenPlan(plan);
    if (plan) {
      // console.log(choosenPlan,"choosenPlan")
      const newTotalPrice = quantity * plan.price;
      setTotalPrice(newTotalPrice.toFixed(2));
    } else {
      const newTotalPrice = quantity * variantData.basePrice;
      setTotalPrice(newTotalPrice.toFixed(2));
    }
  };

  const CalculatePercentageOff = (actualValue, planValue) => {
    const off = ((actualValue - planValue) / actualValue) * 100;
    // Round 'off' to one decimal place
    const roundedOff = off.toFixed(1);

    return parseFloat(roundedOff); // Convert it back to a float
  };

  const formik = useFormik({
    initialValues: {
      quantities: 1,
    },
    validationSchema: quantityValidationSchema,
    onSubmit: async (values) => {

      handleBuyNow(values);
    },
  });

  async function getProductDetails() {
    return await fetchData("/api/catalog/" + productId);

  }

  async function getAllPlans() {
    return await fetchData("/api/catalog/plans/all?catalogId=" + productId);
  }

  useEffect(() => {
    async function fetchData() {
      try {
        dispatcher(addToCart({}));
        setPageLoader(true);
        const productId = localStorage.getItem("productId");
        const productResponse = await getProductDetails(productId);
        console.log("🚀 ~ fetchData ~ productResponse:", productResponse)
        const product = productResponse.data;
        SetProductImage(imageSource + "/" + product.productImage);
        setVariantData(product.variants[0]);

        setProductData(product);
        setSelectedValue(product.variants[0].id);

        const planDetails = await getAllPlans(productId, "productId");
        
        filterVariantPlans(product.variants[0].id, planDetails.data.data);

        setAllPlans(planDetails.data.data);
        const newTotalPrice = quantity * product.variants[0].basePrice;
        setTotalPrice(newTotalPrice);

        const procedure = localStorage.getItem("procedure");
        if (procedure === "store-consultation") {
          localStorage.removeItem("procedure");
        }
        radioRef.current.checked = true;
      } catch (err) {
        // Handle errors
      } finally {
        setTimeout(() => {
          setPageLoader(false);
        }, 2000);
      }
    }
    fetchData();
  }, []);


  console.log('ress', radioRef)
  return (
    <div>
      <Header></Header>
      <div className="main_content product_details">
        <div className="container">

          <div className="main_block">
            {productData ? (
              <form onSubmit={formik.handleSubmit}>
                <div className="product_block">
                  <div className="row">
                    <div className="col-md-5 side left_side">
                      <div className="product_img">
                        <picture className="block md:hidden md:px-4 md:w-1/3 max-w-[120px] md:max-w-full">
                          <LazyLoadImage
                            alt="Product"
                            className="img"
                            effect="blur"
                            src={productImage}
                          />
                        </picture>
                      </div>
                    </div>
                    <div className="col-md-7 side right_side">
                      <div className="product_desc mt-5">
                        <div className="pro_info">
                          <h2>{productData.productName} </h2>
                          {/* <Link className="linkto" to="/">Learn more</Link> */}
                        </div>
                        <div className="product_item">
                          <div className="left_part part">
                            <label>Strength</label>
                            {/* <Link className="linkto" to="/">Learn more</Link> */}
                          </div>
                          <div className="right_part part">
                            <div className="form_field_wrapper">
                              <FormControl x={{ minWidth: 250 }}>
                                <Select
                                  value={selectedValue}
                                  onChange={handleSelectChange}
                                  displayEmpty
                                  MenuProps={{
                                    sx: {
                                      "& .MuiPaper-root ": {
                                        boxShadow: "none",
                                        backgroundColor: "transparent",
                                      },
                                    },
                                    PaperProps: {
                                      sx: {
                                        "& .MuiList-root ": {
                                          padding: "0",
                                          borderRadius: "8px",
                                          overflow: "hidden",
                                          border: "1px solid #dddddd",
                                        },
                                        "& .MuiMenuItem-root": {
                                          padding: "5px 20px",
                                          marginTop: "0",
                                          minHeight: "50px",
                                          fontFamily: "Inter",
                                          fontWeight: 500,
                                          backgroundColor: "#ffffff",
                                          display: "flex",
                                          alignItems: "center",
                                          gap: "0 15px",
                                          justifyContent: "space-between",
                                        },
                                        "& .MuiMenuItem-root .item_price": {
                                          color: "#0BDA51",
                                          fontWeight: 500,
                                        },
                                        "& .MuiMenuItem-root.Mui-selected": {
                                          fontFamily: "Inter",
                                          fontWeight: 500,
                                          backgroundColor: "#EBEBEB !important",
                                        },
                                        "& .MuiMenuItem-root.Mui-selected:hover":
                                        {
                                          fontWeight: 500,
                                          backgroundColor:
                                            "#EBEBEB !important",
                                        },
                                        "& .MuiMenuItem-root:hover": {
                                          fontWeight: 500,
                                          backgroundColor: "#EBEBEB !important",
                                        },
                                      },
                                    },
                                  }}
                                  inputProps={{ "aria-label": "Without label" }}
                                >
                                  {productData.variants.map((row) => (
                                    <MenuItem key={row.id} value={row.id}>
                                      <span className="item_price">
                                        {row.strength}
                                      </span>
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </div>
                          </div>
                        </div>

                        <div className="product_item">
                          <div className="left_part part">
                            <label>Quantity</label>
                          </div>
                          <div className="right_part part">
                            <div className="form_field_wrapper">

                              <FormControl x={{ minWidth: 250 }}>
                                <input
                                  type="number"
                                  placeholder="Enter Quantity"
                                  className="form-control inp_text"
                                  value={formik.values.quantities}
                                  onChange={handleQuantityChange}
                                  id="quantities"
                                  name="quantities"
                                />
                              </FormControl>
                              <p className="error">

                              </p>
                              {!formik.isValid ? <p className='error'>{formik.errors?.quantities}</p> : ''}
                            </div>
                          </div>
                        </div>

                        <div className="product_item">
                          <div className="form_field_wrapper">
                            <label className="custom_radiobtn">
                              <input
                                type="radio"
                                checked={cartProduct.type=='oneTime'}
                                name="paymentType"
                                value={'oneTime'}
                                key="oneTime"
                                ref={radioRef}
                                onClick={() => {
                                  handlePaymentTypeChange("oneTime", "", "");
                                }}
                              />
                              <span className="checkmark"></span>
                              One time purchase: ${variantData.basePrice} /unit
                            </label>
                          </div>
                        </div>
                        {variantPlans &&
                          variantPlans.map((variant) => (
                            <div className="product_item" key={variant.planId}>
                              <div className="form_field_wrapper">
                                <label className="custom_radiobtn">
                                  <input
                                    type="radio"
                                    //checked={cartProduct.type=='subscription'}
                                    name="paymentType"
                                    key={variant.planId}
                                    value={variant.planId}
                                    onClick={() => {
                                      handlePaymentTypeChange(
                                        "subscription",
                                        variant.id,
                                        variant
                                      );
                                    }}
                                  />
                                  <span className="checkmark"></span>
                                  Subscribe & save (
                                  {CalculatePercentageOff(
                                    variantData.basePrice,
                                    variant.price
                                  )}
                                  %): ${variant.price} /unit
                                  <br />
                                  Delivery after every {
                                    variant.interval_count
                                  }{" "}
                                  {variant.interval_unit}
                                </label>
                              </div>
                            </div>
                          ))}

                        <div className="product_item">
                          <div className="form_field_wrapper">
                            <span className="price_total">
                              {" "}
                              {`$${totalPrice} `}
                            </span>
                            {choosenPlan.price && (
                              <span>{`($${choosenPlan.price} /unit)`}</span>
                            )}
                          </div>
                        </div>

                        <div className="product_item">
                          <div className="form_field_wrapper">
                            <button
                              type="submit"
                              variant="contained"
                              color="info"
                              size="large"
                              className="btn btn-primary"
                              starticon={<ShoppingCartIcon />}
                            >
                              Go to checkout
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            ) : (
              <div className="product-detail-container">
                <div className="alert">
                  <span className="alertText">No Product Details Found</span>
                  <span className="alertText">
                    {" "}
                    <p>
                      Sorry, the details for the requested product could not be
                      found.
                    </p>
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default Product_Details;
