
import alertUtils from "../utils/common.utils";
import axiosInstance from "./interceptor";
import axios from "axios";
export const fetchData = async (endpoint) => {
    try {
        const response = await axiosInstance.get(endpoint);
        return response.data;
    } catch (error) {
        return error.data;
      }
};

export const deleteData = async (endpoint) => {
  try {
      const response = await axiosInstance.delete(endpoint);
      return response.data;
  } catch (error) {
      return error.data;
    }
};


export const SubmitData = async (endpoint, data) => {

    try {
        const response = await axiosInstance.post(endpoint, data);
        return response.data;
    } catch (error) {
      return error.data;
    }
};




export const uploadMultipartData = async (endpoint, formData) => {
  console.log(endpoint,formData)
  console.log("🚀 ~ uploadMultipartData ~ formData:", formData)

    try {
      const response = await axiosInstance.post(endpoint, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
      return response.data;
    } catch (error) {
      console.log(error)
        return error.data;
    }
  };
  
  export const updateData = async (endpoint, formData) => {
    console.log(endpoint,formData)
      try {
        console.log("🚀 ~ updateData ~ formData:", formData)
        const response = await axiosInstance.put(endpoint, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        });
        return response.data;
      } catch (error) {
        console.log(error)
          return error.data;
      }
    };
    

    export const updateStatus = async (endpoint) => {
      console.log(endpoint)
        try {
      
          const response = await axiosInstance.patch(endpoint, {
            headers: {
              'Content-Type': 'multipart/form-data',
            }
          });
          return response.data;
        } catch (error) {
          console.log(error)
            return error.data;
        }
      };

  export const fetchBlob = async (endpoint) => {
    try {
        const response = await axiosInstance.get(endpoint, {
            responseType: 'blob',
        });
   
        return response;
    } catch (error) {
        return error.data;
    }
};